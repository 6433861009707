
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- Table -->
            <div class="row justify-content-md-center mt-4">
              <div class="col-md-12">
                <div class="row align-items-center">
                  <div class="col-sm-4 col-md-3">
                    <div class="mb-3 position-relative">
                      <label class="d-inline align-items-center"> สาขา:</label>
                      <multiselect
                        v-model="branchIdSearch"
                        :options="localData"
                        label="nameTh"
                        :show-labels="false"
                      >
                      </multiselect>
                    </div>
                  </div>
                  <div class="col-sm-4 col-md-3">
                    <label class="d-inline align-items-center m-2 text-end">
                      ชื่อ-นามสกุลลูกค้า:
                      <b-form-input
                        v-model="filter.customerNameTh"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control"
                        @keyup.enter="handleSearch"
                      ></b-form-input>
                    </label>
                  </div>

                  <div class="col-sm-4 col-md-3">
                    <label class="d-inline align-items-center m-2 text-end">
                      ทะเบียนรถ:
                      <b-form-input
                        v-model="filter.licensePlate"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control"
                      ></b-form-input>
                    </label>
                  </div>
                  <div class="col-md-3 col-sm-4">
                    <div class="mb-3 position-relative">
                      <label class="d-inline align-items-center m-2">
                        จากวันที่:</label
                      >
                      <!-- disabled="disabled"  -->
                      <date-picker
                        v-model="filter.startDate"
                        :first-day-of-week="1"
                        format="YYYY-MM-DD"
                        value-type="format"
                        lang="en"
                      ></date-picker>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-4">
                    <div class="mb-3 position-relative">
                      <label class="d-inline align-items-center m-2">
                        ถึงวันที่:</label
                      >
                      <!-- disabled="disabled"  -->
                      <date-picker
                        v-model="filter.endDate"
                        :first-day-of-week="1"
                        format="YYYY-MM-DD"
                        value-type="format"
                        lang="en"
                      ></date-picker>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-9 text-end">
                    <btnComponent @click="handleSearch" changeStyle="search">
                    </btnComponent
                    >&nbsp;&nbsp;&nbsp;&nbsp;

                    <!-- <router-link
                      :to="{ name: 'add-receiveDocReg' }"
                      class="btn btn-primary"
                    >
                      <i class="mdi mdi-plus me-1"></i>สร้าง
                    </router-link> -->
                  </div>
                  <!-- <div class="row float-end"></div> -->
                </div>
              </div>
            </div>
            <div class="row justify-content-md-center mt-2">
              <div class="col-sm-12 col-md-12">
                <div class="row align-items-center">
                  <div class="col-sm-12 col-md-12">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        แสดงผล&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                          @input="handlePageChange"
                        ></b-form-select
                        >&nbsp;รายการ
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <b-skeleton-wrapper :loading="loading">
              <!-- skeleton  -->
              <template #loading>
                <b-skeleton-table></b-skeleton-table>
              </template>
              <tableData :fields="fields" :items="rowData">
                <template #cell(sellPrice)="rowData">
                  {{ Number(rowData.item.sellPrice).toLocaleString() }}
                </template>
                <template #cell(index)="rowData" v-if="this.currentPage > 1">
                  {{ rowData.index + 1 + (currentPage * perPage - perPage) }}
                </template>
                <template #cell(index)="rowData" v-else>
                  {{ rowData.index + 1 }}
                </template>
                <template #cell(date)="rowData">
                  <changeDate :date="rowData.item.date"></changeDate>
                </template>
                <template #cell(status)="rowData">
                  <span v-if="rowData.item.status === 'BRCUS'">
                    สาขารับจากลูกค้าแล้ว
                  </span>
                  <span v-if="rowData.item.status === 'BSRGD'">
                    สาขาส่งให้แผนกทะเบียนแล้ว
                  </span>
                  <span v-if="rowData.item.status === 'RGDRB'">
                    แผนกทะเบียนรับจากสาขาแล้ว
                  </span>
                  <span v-if="rowData.item.status === 'RGDSM'">
                    แผนกทะเบียนส่งให้ผู้เดินทะเบียนแล้ว
                  </span>
                  <span v-if="rowData.item.status === 'RGDRM'">
                    แผนกทะเบียนรับจากผู้เดินทะเบียนแล้ว
                  </span>
                  <span v-if="rowData.item.status === 'RGDSB'">
                    แผนกทะเบียนส่งคืนสาขาแล้ว
                  </span>
                  <span v-if="rowData.item.status === 'BRRGD'">
                    สาขารับจากแผนกทะเบียนแล้ว
                  </span>
                  <span v-if="rowData.item.status === 'BSCUS'">
                    สาขาส่งคืนลูกค้าแล้ว
                  </span>
                </template>
                <template #cell(action)="rowData">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <router-link
                        class="px-2 text-primary"
                        v-b-tooltip.hover
                        title="Edit"
                        :to="{
                          name: 'edit-receiveDocReg',
                          params: { regJobId: rowData.item.regJobIdBase64 },
                        }"
                      >
                        <i class="uil uil-pen font-size-18"></i>
                      </router-link>

                      <!-- <a
                        v-if="rowData.item.status != 'SUBMIT'"
                        class="px-2 text-danger"
                        v-b-tooltip.hover
                        title="Delete"
                        @click="alert(rowData.item.regJobId)"
                      >
                        <i class="uil uil-trash-alt font-size-18"></i>
                      </a> -->
                    </li>
                  </ul>
                </template>
              </tableData>

              <br />
              <b-modal
                ref="modalImg"
                id="modalImg"
                title="ภาพประกอบ"
                hide-footer
                size="l"
                centered
              >
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 align-items-center">
                      <div class="row align-items-center">
                        <img
                          :src="this.imagePath"
                          class="preview-img"
                          @click="singleImage = true"
                        />
                        <vue-easy-lightbox
                          :visible="singleImage"
                          :imgs="this.imagePath"
                          @hide="singleImage = false"
                        ></vue-easy-lightbox>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="row">
                  <div class="col-md">
                    <button class="btn btn-success float-end">ตกลง</button>
                  </div>
                </div> -->
              </b-modal>
              <div class="row">
                <span
                  v-if="this.totalRecord === 0"
                  class="text-center font-size-16"
                  >ไม่พบรายการ</span
                >
                <div class="col" v-if="this.totalRecord > 0">
                  <div class="col-md-12">
                    <div class="align-items-center">
                      หน้า {{ currentPage }} จาก {{ this.totalPage }} ทั้งหมด
                      {{ totalRecord }} รายการ
                    </div>
                    <div
                      class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-end
                      "
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="totalRecord"
                          :per-page="perPage"
                          @change="handleChangePage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </b-skeleton-wrapper>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
import tableData from "@/components/tablecomponent";
import btnComponent from "@/components/btnComponent.vue";
import Multiselect from "vue-multiselect";
import ChangeDate from "@/components/changeDateComponent";
import DatePicker from "vue2-datepicker";

// import branchComponent from "@/components/branchComponent.vue";

export default {
  page: {
    title: appConfig.receiveDocReg,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    tableData,
    btnComponent,
    Multiselect,
    ChangeDate,
    DatePicker,
  },

  data() {
    return {
      loading: undefined,
      singleImage: false,
      imagePath: "",
      title: appConfig.receiveDocReg,
      items: [
        {
          text: "งานทะเบียน",
          active: true,
        },
        {
          text: appConfig.receiveDocReg,
          active: true,
        },
      ],
      // partId: this.$route.params.partId,
      model: [],
      totalPage: "",
      branchId: [],
      selectMode: "multi",
      selected: [],
      isLoading: "",
      id: "",
      page: "",
      to: "",
      total: "",
      from: "",
      totalRecord: "",
      rowData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: {
        startDate: "",
        endDate: "",

        branchId: "",
        customerNameTh: "",
        customerFamilyNameTh: "",
        licensePlate: "",
      },
      branchIdSearch: "",
      localDataBranchId: [],
      filterOn: [],
      localData: [],
      sortBy: "age",
      sortDesc: false,
      selectedUsers: [],
      isSelectedAll: false,
      fields: [
        {
          key: "index",
          label: "ลำดับ",
        },
        {
          key: "regCode",
          label: "เลขที่ใบแจ้งจำหน่าย",
          sortable: true,
        },

        {
          key: "regJobCode",
          label: "เลขที่่ส่งเอกสาร",
          sortable: true,
        },

        {
          key: "customerNameTh",
          label: "ชื่อลูกค้า",
        },
        {
          key: "customerFamilyNameTh",
          label: "นามสกุลลูกค้า",
        },
        {
          key: "person",
          label: "ผู้เดินทะเบียน",
        },

        {
          key: "date",
          label: "วันที่ส่งเอกสาร",
        },

        {
          key: "vin",
          label: "เลขตัวถัง",
          sortable: true,
        },
        {
          key: "licensePlate",
          label: "ทะเบียนรถ",
          sortable: true,
        },
        {
          key: "modelNameTh",
          label: "รุ่นรถ",
          sortable: true,
        },
        {
          key: "status",
          label: "สถานะ",
        },
        {
          key: "branchName",
          label: "สาขา",
        },
        {
          key: "action",
          label: "แก้ไข",
        },
      ],
    };
  },

  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.rowData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.rowData = this.items.length;
  },
  created() {
    this.getLocalData();
    this.getData();
  },
  methods: {
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;

      const localDataBranch = [];
      const localDataBranchId = [];
      // const localBranchIdItem ={};
      this.localData.forEach((item) => {
        localDataBranch.push(item), localDataBranchId.push(item.branchId);
      });
      this.localDataBranch = localDataBranch;
      this.localDataBranchId = localDataBranchId;

      // //("สาขาาาาาา", this.localDataBranchId);
    },

    handleSearch() {
      if (this.branchIdSearch === "") {
        this.localDataBranchId;
      } else {
        this.localDataBranchId = [this.branchIdSearch.branchId];
      }
      this.getData();
    },
    onFiltered(filteredItems) {
      this.rowData = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelected(rowData) {
      this.selected = rowData;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },

    getData: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/reg-rcvd-msg", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.localDataBranchId,
            startDate: this.filter.startDate,
            endDate: this.filter.endDate,
            customer: this.filter.customerNameTh,
            licensePlate: this.filter.licensePlate,
          },
        })
        .then((response) => {
          this.rowData = response.data.data;
          const per_Page = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / per_Page);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    deleteParts: function (rowData) {
      this.partData = rowData;
      // //(this.partData);
      this.loading = true; //skeleton true
      useNetw
        .delete("api/part-lost-sale/delete", {
          data: {
            plsId: this.partData,
          },
        })
        .then((response) => {
          // alert("ต้องการลบหรือไม่")
          this.getData();
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    alert(rowData) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบหรือหรือไม่ ?",
          icon: "warning",
          confirmButtonText: "ลบ",
          cancelButtonText: "ยกเลิก",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.deleteParts(rowData);
          }
        });
    },
    getDataloadImage: function (data) {
      this.rcvdId = data.rcvdId;
      this.fileId = data.fileId;
      // this.overlayFlag = true;
      // this.loading = true; //skeleton true
      useNetw
        .get("api/reg-rcvd-msg/full-size-image", {
          params: {
            rcvdId: this.rcvdId,
            fileId: this.fileId,
          },
        })
        .then((response) => {
          //(this.imagePath);
          this.rowImg = response.data.data;
          this.imagePath = response.data.data.filePath;
          this.$refs["modalImg"].show();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          // this.loading = false; //skeleton false
        });
    },
    alertNull() {
      Swal.fire(
        appConfig.swal.title.error,
        JSON.stringify("ไม่พบข้อมูลเอกสารที่ระบุ"),
        appConfig.swal.type.error
      );
    },
  },
  middleware: "authentication",
};
</script>
<style lang="scss" scoped>
.preview-img {
  max-width: 200px;
}
</style>
